.vaccines {
  margin-top: 4rem;
  padding: 1rem 12rem;
  min-height: 90vh;
}

.vaccine_science {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.vaccine {
  width: 15rem;
  /* height: 14rem; */
  margin: 0.5rem;
  border-radius: 15px;
  padding: 0 1rem;
}

.vaccine:hover {
  cursor: pointer;
}

.vaccine img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  margin-bottom: 1rem;
  border-radius: 15px;
}

.vaccine h6 {
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .vaccines {
    margin-top: 4rem;
    padding: 1rem;
    min-height: 90vh;
  }

  .vaccine_science {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .vaccine {
    width: 100%;
    /* height: 14rem; */
    margin: 0.5rem;
    border-radius: 15px;
    padding: 0 1rem;
  }

  .vaccine:hover {
    cursor: pointer;
  }

  .vaccine img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    margin-bottom: 1rem;
    border-radius: 15px;
  }

  .vaccine h6 {
    font-weight: 700;
  }
}
