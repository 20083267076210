.navbar,
.navbar-default {
  background: white !important;
  border-bottom: 1px rgb(232, 233, 235) solid;
}

.nav-link {
  color: black;
}

.board_members {
  padding: 4rem 12rem 1rem;
  min-height: 100vh;
}

.board_members_heading_img {
  height: 8rem;
  width: 8rem;
  border-radius: 4rem;
  overflow: hidden;
}

.board_members_heading_img img {
  height: 100%;
  width: 100%;
}

.board_members_heading h5 {
  font-weight: 700;
  margin: 0rem;
}

.board_members_heading p {
  color: #5f5f60;
  margin: 0rem;
}

.board_members_heading {
  display: flex;
  align-items: center;
  margin: 1rem 0rem 2rem;
}

.board_members_heading_info {
  width: 85%;
  padding: 0rem 1rem;
}

.board_member {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
  cursor: pointer;
}

.board_member:hover b {
  color: #0804ea;
  scale: 1.2;
}

.board_member_img {
  height: 4rem;
  width: 4rem;
  border-radius: 3rem;
  overflow: hidden;
}

.board_member_img img {
  height: 100%;
  width: 100%;
}

.board_member_info {
  padding: 0rem 1rem;
  width: 85%;
}

.board_member h6 {
  font-weight: 600;
  margin: 0rem;
  text-transform: capitalize;
}

.board_member p {
  color: #5f5f60;
  margin: 0rem;
  font-size: small;
}

@media screen and (max-width: 768px) {
  .navbar,
  .navbar-default {
    background: white !important;
    border-bottom: 1px rgb(232, 233, 235) solid;
  }

  .nav-link {
    color: black;
  }

  .board_members {
    padding: 4rem 1rem 1rem;
    min-height: 100vh;
  }

  .board_members_heading_img {
    height: 8rem;
    width: 8rem;
    border-radius: 4rem;
    overflow: hidden;
  }

  .board_members_heading_img img {
    height: 100%;
    width: 100%;
  }

  .board_members_heading h5 {
    font-weight: 700;
    margin: 0rem;
  }

  .board_members_heading p {
    color: #5f5f60;
    margin: 0rem;
  }

  .board_members_heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0rem 2rem;
  }

  .board_members_heading_info {
    width: 100%;
    padding: 0rem;
  }

  .board_member {
    display: flex;
    align-items: start;
    margin: 1rem 0rem;
    cursor: pointer;
  }

  .board_member:hover b {
    color: #0804ea;
    scale: 1.2;
  }

  .board_member_img {
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
    overflow: hidden;
  }

  .board_member_img img {
    height: 100%;
    width: 100%;
  }

  .board_member_info {
    padding: 0rem 1rem;
    width: 85%;
  }

  .board_member h6 {
    font-weight: 600;
    margin: 0rem;
    text-transform: capitalize;
  }

  .board_member p {
    color: #5f5f60;
    margin: 0rem;
    font-size: small;
  }
}
