.board_member_profile {
  padding: 1rem 12rem;
}

.board_member_header_img {
  height: 10rem;
  width: 10rem;
  border-radius: 5rem;
  overflow: hidden;
}

.board_member_header_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.board_member_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
}

h4 {
  color: black;
  font-weight: 700;
  margin: 1rem 0rem 0rem;
}

.board_member_header p {
  font-size: 1.1rem;
  font-weight: 500;
  color: #767686;
}

.blue {
  border: none;
  background-color: #0804ea;
  color: white;
  padding: 0.8rem 1.2rem;
  border-radius: 15px;
  font-weight: bold;
}

.gray {
  border: none;
  background-color: #e8e8ef;
  color: black;
  padding: 0.8rem 1.2rem;
  border-radius: 15px;
  font-weight: bold;
  margin-right: 1rem;
}

.board_member_header div {
  margin-top: 1rem;
}

.board_member_biography {
  padding: 2rem 0rem;
}

.board_member_biography h4 {
  margin-bottom: 1rem;
}

.board_member_biography p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.board_member_professional_achievements {
  padding: 0rem 0rem 2rem;
}

.board_member_professional_achievements h4 {
  margin-bottom: 2rem;
}

.board_member_professional_achievements h6 {
  /* font-weight: 700; */
  font-size: 1.1rem;
  margin: 0rem;
}

.ball {
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  background-color: #0804ea;
}

.achievement {
  display: flex;
  margin: 2rem 0rem;
}

.achievement div {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.achievement p {
  color: #767686;
}

.board_member_contributions h4 {
  margin-bottom: 1rem;
}

.board_member_contributions p {
  font-size: 1.1rem;
}

.board_member_contributions {
  margin-bottom: 2rem;
}

.board_member_contact_info h4 {
  margin-bottom: 2rem;
}

.contact_info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contact_info p {
  color: #767686;
}

.board_member_contact_info {
  margin: 1rem 0rem 2rem;
}

@media screen and (max-width: 768px) {
  .board_member_profile {
    padding: 1rem;
  }

  .board_member_header_img {
    height: 6rem;
    width: 6rem;
    border-radius: 5rem;
    overflow: hidden;
  }

  .board_member_header_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .board_member_header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0rem;
  }

  h4 {
    color: black;
    font-weight: 700;
    margin: 1rem 0rem 0rem;
  }

  .board_member_header p {
    font-size: 1.1rem;
    font-weight: 500;
    color: #767686;
  }

  .blue {
    border: none;
    background-color: #0804ea;
    color: white;
    padding: 0.8rem 1.2rem;
    border-radius: 15px;
    font-weight: bold;
  }

  .gray {
    border: none;
    background-color: #e8e8ef;
    color: black;
    padding: 0.8rem 1.2rem;
    border-radius: 15px;
    font-weight: bold;
    margin-right: 1rem;
  }

  .board_member_header div {
    margin-top: 1rem;
  }

  .board_member_biography {
    padding: 1rem 0rem 2rem;
  }

  .board_member_biography h4 {
    margin-bottom: 1rem;
  }

  .board_member_biography p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .board_member_professional_achievements {
    padding: 0rem 0rem 2rem;
  }

  .board_member_professional_achievements h4 {
    margin-bottom: 2rem;
  }

  .board_member_professional_achievements h6 {
    /* font-weight: 700; */
    font-size: 1.1rem;
    margin: 0rem;
  }

  .ball {
    height: 1rem;
    width: 1rem;
    border-radius: 2rem;
    background-color: #0804ea;
  }

  .achievement {
    display: grid;
    grid-template-columns: 6% 1fr;
    margin: 2rem 0rem 0rem;
  }

  .achievement div {
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .achievement p {
    color: #767686;
  }

  .board_member_contributions h4 {
    margin-bottom: 1rem;
  }

  .board_member_contributions p {
    font-size: 1.1rem;
  }

  .board_member_contributions {
    margin-bottom: 0;
  }

  .board_member_contact_info h4 {
    margin-bottom: 2rem;
  }

  .contact_info {
    display: flex;
    flex-direction: column;
  }

  .contact_info p {
    color: #767686;
    margin: 0;
  }

  .board_member_contact_info {
    margin: 1rem 0rem 2rem;
  }
}
