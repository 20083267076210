.navbar,
.navbar-default {
  background: white !important;
  border-bottom: 1px rgb(232, 233, 235) solid;
}

.nav-link {
  color: black;
}

.programs {
  margin-top: 4rem;
  padding: 1rem 12rem;
}

.programs_banner {
  background-image: url("./components//banner.webp");
  height: 30rem;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
}

.programs_banner_background {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 25px;
  padding: 0 2rem;
}

.programs_banner_background h1 {
  font-size: 3.5rem;
  font-weight: 850;
}

.on_going_programs {
  margin: 3rem 0;
}

.on_going_programs h4 {
  font-weight: 700;
  margin-bottom: 1rem;
}

.program_cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.program_card img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border-radius: 15px;
}

.program_card h6 {
  font-weight: 700;
  font-size: 1.1rem;
  margin: 1rem 0 0;
}

.program_card p {
  font-size: 0.9rem;
  margin: 0.5rem 0;
  color: rgb(109, 109, 110);
}

.show_programs {
  display: flex;
  flex-direction: row-reverse;
}

.show_programs button {
  background-color: #d1d1d6;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  margin-left: 1rem;
  font-weight: bold;
}

.previous_programs_cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.previous_programs_card img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border-radius: 15px;
}

.previous_programs_cards_container h4 {
  margin-bottom: 1rem;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .navbar,
  .navbar-default {
    background: white !important;
    border-bottom: 1px rgb(232, 233, 235) solid;
  }

  .nav-link {
    color: black;
  }

  .programs {
    margin-top: 4rem;
    padding: 1rem;
  }

  .programs_banner {
    background-image: url("./components//banner.webp");
    height: 20rem;
    background-size: cover;
    background-position: center;
    border-radius: 25px;
  }

  .programs_banner_background {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 25px;
    padding: 0 2rem;
  }

  .programs_banner_background h1 {
    font-size: 2rem;
    font-weight: 850;
  }

  .on_going_programs {
    margin: 3rem 0;
  }

  .on_going_programs h4 {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .program_cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
  }

  .program_card img {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    border-radius: 15px;
  }

  .program_card h6 {
    font-weight: 700;
    font-size: 1.1rem;
    margin: 1rem 0 0;
  }

  .program_card p {
    font-size: 0.9rem;
    margin: 0.5rem 0;
    color: rgb(109, 109, 110);
  }

  .show_programs {
    display: flex;
    flex-direction: row-reverse;
  }

  .show_programs button {
    background-color: #d1d1d6;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    margin-left: 1rem;
    font-weight: bold;
  }

  .previous_programs_cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
  }

  .previous_programs_card img {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    border-radius: 15px;
  }

  .previous_programs_cards_container h4 {
    margin-bottom: 1rem;
    font-weight: 700;
  }
}
