.donations {
  margin-top: 4rem;
  padding: 1rem 12rem;
}

.donations_banner {
  background-image: url("./components/donation.png");
  height: 30rem;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
}

.donations_banner_background {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 25px;
  padding: 0 2rem;
}

.donations_banner_background h1 {
  font-size: 3.5rem;
  font-weight: 850;
}

.donations_content {
  margin: 3rem 0;
}

.donations_content p {
  font-size: 1.2rem;
  margin: 1rem 0;
}

@media screen and (max-width: 768px) {
  .donations {
    margin-top: 4rem;
    padding: 1rem;
    min-height: 90vh;
  }

  .donations_banner {
    background-image: url("./components/donation.png");
    height: 20rem;
    background-size: cover;
    background-position: center;
    border-radius: 25px;
  }

  .donations_banner_background {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 25px;
    padding: 0 2rem;
  }

  .donations_banner_background h1 {
    font-size: 2rem;
    font-weight: 850;
  }

  .donations_content {
    margin: 1rem 0;
  }

  .donations_content p {
    font-size: 1.2rem;
    margin: 1rem 0;
  }
}
