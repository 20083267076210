.navbar,
.navbar-default {
  background: white !important;
  border-bottom: 1px rgb(232, 233, 235) solid;
}

.nav-link {
  color: black;
}

.about {
  padding: 1rem 12rem;
  margin-top: 4rem;
}

.about p {
  font-size: 1.1rem;
}

.about_banner {
  height: 30rem;
}

.about_banner_background {
  background-color: black;
  background-image: url("./components/about.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  border-radius: 1.5rem;
  color: white;
}

.about_banner_body {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  border-radius: 1.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.about_banner_body h1 {
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
}

.about_banner_body button {
  border: none;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-radius: 1rem;
  background-color: #0804ea;
  color: white;
  font-weight: bold;
}

.about_history {
  margin-top: 2rem;
}

.about_history h4 {
  font-weight: 700;
}

.about_vision {
  margin-top: 4rem;
}

.about_vision h4 {
  font-weight: 700;
}

.about_team {
  margin-top: 4rem;
}

.about_team h4 {
  font-weight: 700;
}

.about_team_member {
  display: flex;
  margin: 1rem 0rem;
}

.about_team_member_img {
  width: 8rem;
  height: 8rem;
  border-radius: 5rem;
  overflow: hidden;
  background-color: #0804ea;
}

.about_team_member_img img {
  width: 100%;
  height: 100%;
}

.about_team_member_info h4,
p {
  margin: 0rem;
}

.about_team_member_info p {
  color: rgb(107, 108, 110);
}

.about_team_member_info {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
}

.about_team_member_info div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about_team_member_info div button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  background-color: #0804ea;
  color: white;
  font-weight: bold;
}

.about_partners {
  margin-top: 4rem;
}

.about_partners h4 {
  font-weight: 700;
}

.about_partners_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.about_partners_container img {
  width: 100%;
  height: 100%;
}

.button {
  border: none;
  padding: 0.1rem 1rem;
  border-radius: 0.5rem;
  background-color: #0804ea;
  color: white;
  font-weight: bold;
}

.about_what_we_do {
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .navbar,
  .navbar-default {
    background: white !important;
    border-bottom: 1px rgb(232, 233, 235) solid;
  }

  .nav-link {
    color: black;
  }

  .about {
    padding: 1rem;
    margin-top: 4rem;
  }

  .about p {
    font-size: 1.1rem;
  }

  .about_banner {
    height: 20rem;
  }

  .about_banner_background {
    background-color: black;
    background-image: url("./components/about.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    border-radius: 1.5rem;
    color: white;
  }

  .about_banner_body {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    border-radius: 1.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .about_banner_body h1 {
    font-size: 1.5rem;
    font-weight: 900;
    text-align: center;
  }

  .about_banner_body button {
    border: none;
    padding: 1rem 2rem;
    margin-top: 1rem;
    border-radius: 1rem;
    background-color: #0804ea;
    color: white;
    font-weight: bold;
  }

  .about_history {
    margin-top: 2rem;
  }

  .about_history h4 {
    font-weight: 700;
  }

  .about_vision {
    margin-top: 2rem;
  }

  .about_vision h4 {
    font-weight: 700;
  }

  .about_team {
    margin-top: 2rem;
  }

  .about_team h4 {
    font-weight: 700;
  }

  .about_team_member {
    display: flex;
    /* margin: 1rem 0rem; */
  }

  .about_team_member_img {
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    overflow: hidden;
    background-color: #0804ea;
  }

  .about_team_member_img img {
    width: 100%;
    height: 100%;
  }

  .about_team_member_info h4,
  p {
    margin: 0rem;
  }

  .about_team_member_info h4{
    font-size: 1.3rem;
  }

  .about_team_member_info p {
    color: rgb(107, 108, 110);
  }

  .about_team_member_info {
    width: 85%;
    display: flex;
    flex-direction: column;
    margin-left: 0.1rem;
  }

  .about_team_member_info div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .about_team_member_info div button {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    background-color: #0804ea;
    color: white;
    font-weight: bold;
  }

  .about_partners {
    margin-top: 4rem;
  }

  .about_partners h4 {
    font-weight: 700;
  }

  .about_partners_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  .about_partners_container img {
    width: 100%;
    height: 100%;
  }

  .button {
    border: none;
    padding: 0.1rem 1rem;
    border-radius: 0.5rem;
    background-color: #0804ea;
    color: white;
    font-weight: bold;
  }

  .about_what_we_do {
    margin-top: 4rem;
  }
}
