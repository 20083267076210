.navbar,
.navbar-default {
  background: white !important;
  border-bottom: 1px rgb(232, 233, 235) solid;
}

.nav-link {
  color: black;
}

.homepage {
  padding: 1rem 12rem;
  margin-top: 4rem;
}

.homepage_banner {
  height: 30rem;
}

.homepage_banner_background {
  background-color: black;
  background-image: url("./components/banner.avif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  border-radius: 1.5rem;
}

.homepage_banner_body {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  border-radius: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_banner_body h1 {
  font-size: 4rem;
  font-weight: bolder;
  text-align: center;
}

.homepage_our_work {
  margin: 4rem 0rem 2rem;
}

.homepage_our_work h1 {
  font-weight: 1000;
}

.homepage_our_work p {
  width: 67%;
}

.homepage_our_work_icons {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 1rem;
}

.homepage_our_work_icon {
  border: 1px solid rgb(221, 223, 227);
  padding: 1rem;
  border-radius: 10px;
}

.homepage_our_work_icon p {
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 1rem;
  font-size: large;
}

.homepage_news {
  margin-top: 4rem;
}

.homepage_news h3 {
  font-weight: 800;
}

.news {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.news img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.news_info {
  padding: 1rem;
}

.news_info h5 {
  font-weight: 800;
  text-transform: capitalize;
}

.news_info div {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.news_info div p {
  font-size: large;
  color: rgb(107, 108, 110);
}

.news_info div button {
  background-color: #0804ea;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}

.join_us {
  padding: 8rem;
}

.join_us h1 {
  font-weight: 1000;
  font-size: 3.5rem;
  text-align: center;
}

.join_us div {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.join_us_blue {
  border: none;
  background-color: #0804ea;
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-weight: bold;
  margin-right: 1rem;
}

.join_us_grey {
  border: none;
  background-color: #e8e8ef;
  color: black;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .navbar,
  .navbar-default {
    background: white !important;
    border-bottom: 1px rgb(232, 233, 235) solid;
  }

  .nav-link {
    color: black;
  }

  .homepage {
    padding: 1rem;
    margin-top: 4rem;
  }

  .homepage_banner {
    height: 20rem;
  }

  .homepage_banner_background {
    background-color: black;
    background-image: url("./components/banner.avif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    border-radius: 1.5rem;
  }

  .homepage_banner_body {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    border-radius: 1.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }

  .homepage_banner_body h1 {
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
  }

  .homepage_our_work {
    margin: 4rem 0rem 2rem;
  }

  .homepage_our_work h1 {
    font-weight: 1000;
  }

  .homepage_our_work p {
    width: 100%;
  }

  .homepage_our_work_icons {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 1rem;
  }

  .homepage_our_work_icon {
    border: 1px solid rgb(221, 223, 227);
    padding: 1rem;
    border-radius: 10px;
  }

  .homepage_our_work_icon p {
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 1rem;
    font-size: large;
  }

  .homepage_news {
    margin-top: 4rem;
  }

  .homepage_news h3 {
    font-weight: 800;
  }

  .news {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  .news img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .news_info {
    padding: 1rem;
  }

  .news_info h5 {
    font-weight: 800;
    text-transform: capitalize;
  }

  .news_info div {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .news_info div p {
    font-size: large;
    color: rgb(107, 108, 110);
  }

  .news_info div button {
    background-color: #0804ea;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
  }

  .join_us {
    padding: 5rem 1rem;
  }

  .join_us h1 {
    font-weight: 1000;
    font-size: clamp(30px, 5vw, 32px);
    text-align: center;
  }

  .join_us div {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .join_us_blue {
    border: none;
    background-color: #0804ea;
    color: white;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-weight: bold;
    margin-right: 1rem;
  }

  .join_us_grey {
    border: none;
    background-color: #e8e8ef;
    color: black;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-weight: bold;
  }
}
