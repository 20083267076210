.footer {
  padding: 2rem 5rem 1rem;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: large;
}

.footer div {
  width: 20%;
  display: flex;
  justify-content: space-between;
}

.footer div a {
  color: black;
}

.footer div a:hover {
  color: #0804ea;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 2rem 1rem 1rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .footer div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .footer div a {
    color: black;
  }

  .footer div a:hover {
    color: #0804ea;
  }
}
