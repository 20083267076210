.navbar,
.navbar-default {
  background: white !important;
  border-bottom: 1px rgb(232, 233, 235) solid;
}

.nav-link {
  color: black;
}

.contact {
  margin-top: 4rem;
  padding: 3rem 12rem 1rem;
}

.contact h2 {
  font-weight: 700;
  margin-bottom: 2rem;
}

.contact p {
  margin: 1rem 0rem 0.5rem;
  color: black;
  font-weight: 500;
}

.contact_form input {
  border: 1px solid rgb(210, 211, 213);
  padding: 0.5rem 1rem;
  width: 40%;
  border-radius: 10px;
}

.contact_form textarea {
  border: 1px solid rgb(210, 211, 213);
  padding: 0.5rem 1rem;
  width: 40%;
  border-radius: 10px;
}

.contact_form button {
  background-color: #0804ea;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  margin-top: 1rem;
  font-weight: bold;
}

.contact_form div {
  display: flex;
  flex-direction: row-reverse;
}

.contact_address {
  margin-top: 2rem;
}

.contact_address p {
  font-weight: 700;
  margin-bottom: 2rem;
  font-size: large;
}

@media screen and (max-width: 768px) {
  .contact {
    padding: 3rem 2rem 1rem;
  }

  .contact_form input {
    width: 100%;
  }

  .contact_form textarea {
    width: 100%;
  }
}
